import React from 'react'
import { Box, Typography, Card } from '@mui/material'
import { LogRecord } from '../interface/LogRecord'
import { FormatDate } from '../utils/DateUtils'

interface LogListProps {
  logs?: LogRecord[]
}

const LogList: React.FC<LogListProps> = ({ logs }) => {
  if (!logs || logs.length === 0 || !Array.isArray(logs)) return null
  return (
    <Box>
      {logs
        .slice()
        .reverse()
        .map((log, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 1,
              padding: 1,
              paddingY: 0,
              borderLeft: `4px solid ${getColor(log.type)}`,
              opacity: index === logs.length - 1 ? 1 : 0.5
            }}
          >
            <Typography variant="caption" color="textSecondary" sx={{ padding: 0 }}>
              {FormatDate(log.createdAt)}
              <b style={{ marginLeft: 10 }}>{log.type.toUpperCase()}</b>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ padding: 0 }}>
              {log.message}
            </Typography>
          </Card>
        ))}
    </Box>
  )
}

// Helper function to set color based on log type
const getColor = (type: LogRecord['type']) => {
  switch (type) {
    case 'info':
      return '#1976d2'
    case 'error':
      return '#d32f2f'
    case 'warning':
      return '#ffa726'
    default:
      return '#757575'
  }
}

export default LogList

// src/pages/Settings.tsx
import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Snackbar, Alert, MenuItem } from '@mui/material'
import axios from 'axios'
import { API_URL } from '../const'
import { RiskBotSettings } from '../interface/RiskBotSettings'
import { convertStringToArray } from '../utils/TypeUtils'

interface SettingsProps {
  botId: number | null
}

const Settings: React.FC<SettingsProps> = ({ botId }) => {
  const [riskBotSettings, setRiskBotSettings] = useState<RiskBotSettings | undefined>()
  const [selectedPair, setSelectedPair] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success')
  const [snackbarOpen2, setSnackbarOpen2] = useState(false)
  const [snackbarMessage2, setSnackbarMessage2] = useState('')
  const [snackbarSeverity2, setSnackbarSeverity2] = useState<'success' | 'error'>('success')

  const [fieldTpTemplate, setFieldTpTemplate] = useState<string>('')
  const [fieldSlTemplate, setFieldSlTemplate] = useState<string>('')
  const [fieldMarginTemplate, setFieldMarginTemplate] = useState<string>('')
  const [pairList, setPairList] = useState<Pair[]>([])

  interface Pair {
    pair: string
  }

  useEffect(() => {
    LoadSettings(botId)
    LoadPairList()
  }, [botId])

  useEffect(() => {
    // console.log('Settings:' + JSON.stringify(riskBotSettings))
  }, [riskBotSettings])

  useEffect(() => {
    const tpTemplateArray = convertStringToArray(fieldTpTemplate.toString())
    const slTemplateArray = convertStringToArray(fieldSlTemplate.toString())
    const marginTemplateArray = convertStringToArray(fieldMarginTemplate.toString())
    if (riskBotSettings !== undefined) {
      setRiskBotSettings({
        ...riskBotSettings,
        tpTemplate: tpTemplateArray,
        slTemplate: slTemplateArray,
        marginTemplate: marginTemplateArray
      })
    }
  }, [fieldTpTemplate, fieldSlTemplate, fieldMarginTemplate, riskBotSettings])

  const SaveApiKeys = async () => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'setApiKeys',
          botId: botId,
          apiKey: apiKey,
          apiSecret: apiSecret
        }
      })
      if (response.data.status) {
        setSnackbarMessage('API Keys saved successfully')
        setSnackbarSeverity('success')
      } else {
        setSnackbarMessage('Failed to save API Keys. Error:' + response.data.error)
        setSnackbarSeverity('error')
      }
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage('Error saving API Keys')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const SaveSettings = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'saveSettings',
          botId: botId,
          data: JSON.stringify(riskBotSettings)
        }
      })
      if (response.data.status) {
        setSnackbarMessage('Settings saved successfully')
        setSnackbarSeverity('success')
        LoadSettings(botIdValue)
      } else {
        setSnackbarMessage('Failed to save Settings: ' + response.data.error)
        setSnackbarSeverity('error')
      }
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage('Error saving Settings')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const SaveSelectedPair = async () => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'setPair',
          botId: botId,
          pair: selectedPair
        }
      })
      if (response.data.status) {
        setSnackbarMessage2(`Pair "${selectedPair}" saved successfully`)
        setSnackbarSeverity2('success')
      } else {
        setSnackbarMessage2('Failed to save pair: ' + response.data.error)
        setSnackbarSeverity2('error')
      }
      setSnackbarOpen2(true)
    } catch (error) {
      setSnackbarMessage2('Error saving pair')
      setSnackbarSeverity2('error')
      setSnackbarOpen2(true)
    }
  }

  const SaveSettingsAndPair = async () => {
    await SaveSettings(botId)
    await SaveSelectedPair()
  }

  const LoadSettings = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadSettings',
          botId: botIdValue
        }
      })
      setRiskBotSettings(response.data)
      setFieldTpTemplate(response.data.tpTemplate)
      setFieldSlTemplate(response.data.slTemplate)
      setFieldMarginTemplate(response.data.marginTemplate)
    } catch (error) {
      setSnackbarMessage('Error load settings')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const LoadPair = async (botIdValue: number | null) => {
    if (botIdValue === null) return

    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadPair',
          botId: botIdValue
        }
      })
      setSelectedPair(response.data)
    } catch (error) {
      setSnackbarMessage('Error load Pair')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const LoadPairList = async () => {
    try {
      const response = await axios.get(API_URL, {
        params: {
          key: 'loadPairList'
        }
      })
      setPairList(response.data)
    } catch (error) {
      setSnackbarMessage('Error load Pair List')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }

  const handleSnackbarClose2 = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setSnackbarOpen2(false)
  }

  useEffect(() => {
    if (setPairList.length > 0) LoadPair(botId)
  }, [setPairList, botId])

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: 2,
          p: 2,
          borderRadius: 2,
          bgcolor: '#ffffff',
          border: 1,
          borderColor: '#cfcfcf',
          boxShadow: 1
        }}
      >
        <TextField
          label="Virtuals to do"
          variant="outlined"
          fullWidth
          value={riskBotSettings?.numberOfVirtualToDo || ''}
          onChange={(e) => {
            if (riskBotSettings !== undefined)
              setRiskBotSettings({
                ...riskBotSettings,
                numberOfVirtualToDo: parseInt(e.target.value, 10) || 0
              })
          }}
        />
        <TextField
          label="TP for Virtual"
          variant="outlined"
          fullWidth
          type="number"
          value={riskBotSettings?.tpForVirtualOnly ?? ''} // Use nullish coalescing to handle undefined
          onChange={(e) => {
            if (riskBotSettings !== undefined) {
              const value = e.target.value === '' ? '' : parseFloat(e.target.value)
              setRiskBotSettings({
                ...riskBotSettings,
                tpForVirtualOnly: value
              })
            }
          }}
        />
        <TextField
          label="Max loss in cycle"
          variant="outlined"
          fullWidth
          value={riskBotSettings?.maxLossLimit || ''}
          onChange={(e) => {
            if (riskBotSettings !== undefined)
              setRiskBotSettings({
                ...riskBotSettings,
                maxLossLimit: parseInt(e.target.value, 10) || 0
              })
          }}
        />
        <TextField
          label="SL for Virtual"
          variant="outlined"
          fullWidth
          type="number"
          value={riskBotSettings?.slForVirtualOnly ?? ''}
          onChange={(e) => {
            if (riskBotSettings !== undefined) {
              const value = e.target.value === '' ? '' : parseFloat(e.target.value)
              setRiskBotSettings({
                ...riskBotSettings,
                slForVirtualOnly: value
              })
            }
          }}
        />
        <TextField
          label="TP template"
          variant="outlined"
          fullWidth
          value={fieldTpTemplate}
          onChange={(e) => {
            setFieldTpTemplate(e.target.value)
          }}
        />
        <TextField
          label="SL template"
          variant="outlined"
          fullWidth
          value={fieldSlTemplate}
          onChange={(e) => {
            setFieldSlTemplate(e.target.value)
          }}
        />
        <TextField
          label="Margin template"
          variant="outlined"
          fullWidth
          value={fieldMarginTemplate}
          onChange={(e) => {
            setFieldMarginTemplate(e.target.value)
          }}
        />

        {pairList.length > 0 && selectedPair && (
          <TextField
            label="Select Pair"
            select
            variant="outlined"
            fullWidth
            value={selectedPair}
            onChange={(e) => setSelectedPair(e.target.value)}
          >
            {pairList.map((item, index) => (
              <MenuItem key={index} value={item.pair}>
                {item.pair}
              </MenuItem>
            ))}
          </TextField>
        )}

        {/* Centered Save button for other settings */}
        <Box
          sx={{
            gridColumn: { xs: 'span 1', sm: 'span 2' },
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button variant="contained" color="primary" onClick={() => SaveSettingsAndPair()}>
            Save Settings
          </Button>
        </Box>
        {/* First Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {/* Second Snackbar */}
        <Snackbar
          open={snackbarOpen2}
          autoHideDuration={3000}
          onClose={handleSnackbarClose2}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleSnackbarClose2} severity={snackbarSeverity2}>
            {snackbarMessage2}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: 2,
          p: 2,
          borderRadius: 2,
          bgcolor: '#ffffff',
          border: 1,
          borderColor: '#cfcfcf',
          marginTop: 4,
          boxShadow: 1
        }}
      >
        {/* API Key and API Secret fields with their own Save button */}
        <TextField
          label="API Key"
          variant="outlined"
          fullWidth
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <TextField
          label="API Secret"
          variant="outlined"
          fullWidth
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
        />

        {/* Centered Save button for API Keys */}
        <Box
          sx={{
            gridColumn: { xs: 'span 1', sm: 'span 2' },
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button variant="contained" color="primary" onClick={SaveApiKeys}>
            Save API Keys
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Settings
